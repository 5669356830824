import React from 'react';

import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

import RailSkeleton from 'components/widgets/RailV2/components/RailSkeleton/RailSkeleton';
import { RAIL_PAGE_SIZE } from 'consts';

import RailProviderV2 from '../components/RailProviderV2/RailProviderV2';

import RentalsRailContainer from './components/RentalsRailContainer/RentalsRailContainer';
import { useRentalsRail } from './hooks/useRentalsRail/useRentalsRail';

interface Props {
  referenceId: string;
  index: number;
}

function RentalsRail({ referenceId, index }: Props) {
  const { railData, railType, railSettings, isRailHidden } = useRentalsRail(referenceId);

  if (!railSettings || isRailHidden) {
    return null;
  }

  if (!railData.items.length) {
    return <RailSkeleton railType={railType} settings={railSettings} items={RAIL_PAGE_SIZE} />;
  }

  return (
    <RailProviderV2<RentalAsset>
      index={index}
      type={railType}
      data={railData}
      settings={railSettings}
      referenceId={referenceId}
    >
      <RentalsRailContainer />
    </RailProviderV2>
  );
}

export default RentalsRail;
