import { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { CardResolvedProps, CollectionGridMediaAsset, CollectionGridMediaAssetWithEpisodes } from 'types';

import { CollectionAsset, CollectionGridSettings } from '@kaltura-ott/tvpil-shared';

import { useCardIconsPaths, useCardLink, useCollectionCardMainProps } from 'components/widgets/Card/hooks';
import { useCollectionGridSettingsContext } from 'context/collectionGridSettingsContext/collectionGridSettingsContext';
import { checkIsMosaicProgram, getSeasonEpisodeTitleV2 } from 'utils';

interface Props {
  data: CollectionAsset;
  gridSettingsData: CollectionGridSettings;
}

function useResolveCollectionGridCardProps({ data, gridSettingsData }: Props) {
  const intl = useIntl();
  const {
    // imageHeight,
    // imageWidth,
    shouldShowRating,
    shouldShowEntitlementIndicator,
    imageType,
    isGrouped,
  } = gridSettingsData;
  const { imageHeight, imageWidth } = useCollectionGridSettingsContext(); // should be removed after fallback params becomes required
  const collectionType = 'collection';
  const ref = useRef<HTMLDivElement | null>(null);
  const setRef = useCallback((value: HTMLDivElement | null) => {
    ref.current = value;
  }, []);

  const {
    imageProps: { height, url, width },
    contentMarker,
    isLinkAsAsset,
    isValidLinkAsAsset,
    isPurchasable,
    isVod,
    isProgramAsset,
    isTypeOfSeries,
    channelInfo,
    isCollectionEpisode,
    adult,
    isAssetPlayOnExternalApp,
    isFuture,
    isCatchupSeries,
    catchUpAirDate,
    hasSeriesId,
    isSingleVod,
    isChannelAsset,
    isCollectionInAppAdultLink,
  } = useCollectionCardMainProps({ data, imageType, imageHeight, imageWidth, isGrouped });
  const { defaultImage, lockIcon, mdIcon } = useCardIconsPaths({ height, width });
  const isGrid = true;
  // TODO: will be done in the next iteration
  const isSuppressedEpisode = false;
  const isProgramRecord = false;
  const mosaicChannelTitle = '';
  const isNoData = false;
  const isLinearAsset = false;
  const isHero = false;
  const isContinueWatching = false;
  const isShowStreams = false;
  const mosaicInfo = { mosaicChannelsInfo: [], isMosaicEvent: undefined };
  const isMdCondition = !!mosaicInfo?.isMosaicEvent;
  const isShowedProgressBar = false;
  const isCatchUpAirDate = false;
  const isNewEpisode = false;
  const isRented = false;
  const isNotHeroAndContinueWatching = true;
  const isMosaic = checkIsMosaicProgram(mosaicInfo) && !!mosaicInfo?.mosaicChannelsInfo?.length;
  const isMosaicHeroRailProgram = isMosaic && isHero;
  const isFutureProgram = isFuture && !isCatchupSeries;
  const isDisplayCartIcon = isPurchasable && !isTypeOfSeries && shouldShowEntitlementIndicator;
  const itemImageUrl = url || defaultImage;
  const isNoDataWithNoLogoImg = isNoData && itemImageUrl === defaultImage;
  const state = {
    isShowStreams: isMosaicHeroRailProgram,
  };
  const isShowAirTime = isNotHeroAndContinueWatching && !isTypeOfSeries && catchUpAirDate;
  const providerName = (data as CollectionGridMediaAsset).providerName!;
  const isExternalProviderAsset = Boolean(providerName) && isAssetPlayOnExternalApp;
  const isEpisodeInfo = isProgramAsset || isCollectionEpisode;
  const isAsPlayAsset = !isTypeOfSeries && !isFuture && !isPurchasable && !isLinkAsAsset;
  const isDisplayContentMarker = !isRented && isVod && !!contentMarker && !providerName;
  const { link, mediaTypeParam } = useCardLink({
    data,
    isSingleVod,
    isFutureProgram,
    collectionType,
    isLinkAsAsset,
    isAsPlayAsset,
    isChannelAsset,
  });
  let seasonEpisodeTitle = '';

  if (isEpisodeInfo) {
    seasonEpisodeTitle = getSeasonEpisodeTitleV2(intl, data as CollectionGridMediaAssetWithEpisodes, ':', true);
  }

  return {
    ref,
    setRef,
    isTypeOfSeries,
    isFuture,
    adult,
    isFutureProgram,
    itemImageUrl,
    defaultImage,
    collectionType,
    state,
    catchUpAirDate,
    isNoDataWithNoLogoImg,
    isShowStreams,
    isLinearAsset,
    isNoData,
    isHero,
    isShowedProgressBar,
    isCatchUpAirDate,
    isDisplayCartIcon,
    isDisplayContentMarker,
    contentMarker,
    isNewEpisode,
    isRented,
    shouldShowRating,
    imageHeight,
    imageType,
    isLinkAsAsset,
    isValidLinkAsAsset,
    isProgramAsset,
    isCollectionEpisode,
    isContinueWatching,
    seasonEpisodeTitle,
    mosaicChannelTitle,
    lockIcon,
    mdIcon,
    isShowAirTime,
    isMosaicHeroRailProgram,
    link,
    isGrid,
    mediaTypeParam,
    providerName,
    isExternalProviderAsset,
    isPurchasable,
    isSuppressedEpisode,
    isMdCondition,
    isProgramRecord,
    channelInfo,
    hasSeriesId,
    isAsPlayAsset,
    isChannelAsset,
    isCollectionInAppAdultLink,
  } as CardResolvedProps;
}

export { useResolveCollectionGridCardProps };
