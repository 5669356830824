import { CollectionAsset, RailSettings } from '@kaltura-ott/tvpil-shared';

import { RAIL_PAGE_SIZE } from 'consts';

import RailSkeleton from '../RailSkeleton/RailSkeleton';

import { useRailWrapperCombiner } from './hooks';

interface Props {
  railSettings: RailSettings;
  railType: string;
  isLoading: boolean;
  items: CollectionAsset[];
  children: React.ReactNode;
  index: number;
  hasError: boolean;
}

function RailWrapper({ railSettings, children, items, railType, index, isLoading, hasError }: Props) {
  const { isSkeletonCondition, handleSkeletonRef } = useRailWrapperCombiner(railSettings, items, railType, index);

  if (!items.length && !isLoading || hasError) return null;

  if (isSkeletonCondition || items.length === 0) {
    return (
      <RailSkeleton railType={railType} settings={railSettings} handleRef={handleSkeletonRef} items={RAIL_PAGE_SIZE} />
    );
  }

  return <>{children}</>;
}

export default RailWrapper;
