import { KalturaRecordingType } from '@kaltura-ott/ott-client-types/api/types/KalturaRecordingType';
import { QuotaStatusEnum } from '@kaltura-ott/tvpil';
import { CollectionAsset, CollectionSeriesAsset } from '@kaltura-ott/tvpil-shared';

import { useRecordRepository } from 'hooks';

interface Props {
  data: CollectionSeriesAsset | CollectionAsset | any;
  isProgramAsset: boolean;
  isTypeOfSeries: boolean;
  isCollectionEpisode: boolean;
  isCatchupSeries?: boolean;
}

function useCardRecordInfo({ data, isProgramAsset, isTypeOfSeries, isCollectionEpisode }: Props) {
  const recordRepository = useRecordRepository();
  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
  const isSeriesId = isProgramAsset || isTypeOfSeries || isCollectionEpisode;
  const singleRecord = recordRepository.records.get(data?.id?.toString()!);
  const seriesRecord =
    isSeriesId && (data as CollectionSeriesAsset)?.seriesId!
      ? recordRepository.seriesRecords.get((data as CollectionSeriesAsset)?.seriesId!)
      : null;
  const isSeriesType = Boolean(seriesRecord || singleRecord?.data.type === KalturaRecordingType.series);
  const isRecord = Boolean(isNpvrEnabled && (singleRecord || seriesRecord));
  // TODO: will be added in the next iteration
  const isRecordExpires = false;
  const recordingExpirationInDays = 0;

  return { isRecord, isSeriesType, isRecordExpires, recordingExpirationInDays };
}

export { useCardRecordInfo };
