import cn from 'classnames';

import { RailSkeletonAsset } from 'components/widgets/Rail/components/RailSkeleton/RailSkeleton';

import styles from './Skeleton.module.scss';

type PersonalizedListSkeletonProps = {
  itemWidth: number;
  itemHeigh: number;
  numberOfElements: number;
  className?: string;
};
export function PersonalizedListSkeleton({
  itemWidth,
  itemHeigh,
  numberOfElements,
  className,
}: PersonalizedListSkeletonProps) {
  return (
    <div className={cn(styles.railSkeleton, styles.isGrid, className)}>
      <header className={styles.titleSkeleton} />
      <header className={styles.descriptionSkeleton} />
      <div>
        {new Array(numberOfElements).fill(0).map(() => {
          return <RailSkeletonAsset height={itemHeigh} width={itemWidth} />;
        })}
      </div>
    </div>
  );
}
