import { CollectionAsset, RailSettings } from '@kaltura-ott/tvpil-shared';

import { getImageSizeParams } from 'utils/getImageSizeParams';

import {
  ADDITIONAL_EPISODE_PADDING,
  ADDITIONAL_HEIGHT,
  CARD_PADDING,
  INITIAL_OFFSET,
  TITLE_BLOCK_MARGIN,
} from '../../constants';

export function calculateItemHeight(railSettings: RailSettings, isLogoRail: boolean) {
  const {
    imageHeight: settingsImageHeight,
    branding: { fontSize },
  } = railSettings;
  const { imageHeight } = getImageSizeParams(railSettings); // should be removed after fallback params becomes required
  const titleBlockHeight = fontSize + TITLE_BLOCK_MARGIN;
  let componentHeight = settingsImageHeight || imageHeight;

  if (!isLogoRail) {
    componentHeight += ADDITIONAL_HEIGHT + titleBlockHeight + ADDITIONAL_EPISODE_PADDING;
  }

  // TODO: must be added the logic for railType === RAIL_TYPE.CW

  return componentHeight;
}

export function calculateTotalWidth(list: CollectionAsset[], imageWidth: number) {
  let width = 0;

  for (let i = 0; i < list.length; i += 1) {
    width += imageWidth + CARD_PADDING;
  }

  return Math.ceil(width) + INITIAL_OFFSET;
}
