import React, { useEffect, useRef } from 'react';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import { autoScrollWindowForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { isChannel } from 'guards/isChannel';

import { Channel } from '@kaltura-ott/tvpil-shared';

import { shiftChannelNumbersBackward, shiftChannelNumbersForward } from '../helper';

type DragAndDropGridContainerProps = {
  originalChannels: Array<Channel>;
  setLineupChange: (arg: Array<Channel>) => void;
  children: React.ReactNode;
};

export function DragAndDropGridContainer({
  children,
  originalChannels,
  setLineupChange,
}: DragAndDropGridContainerProps) {
  const mainRef = useRef<HTMLImageElement>(null);

  const sourceIndexRef = useRef<null | number>(null);
  const previewChannelsRef = useRef<null | Array<Channel>>(null);

  useEffect(() => {
    return autoScrollWindowForElements({
      getConfiguration: () => ({
        maxScrollSpeed: 'fast',
      }),
    });
  });

  useEffect(() => {
    return monitorForElements({
      onDragStart: () => {
        previewChannelsRef.current = originalChannels;
      },
      onDropTargetChange({ source, location }) {
        if (!previewChannelsRef.current) {
          return;
        }

        const destination = location.current.dropTargets[0];

        const currentTargetChannel = location.current.dropTargets[0]?.data.channel;
        const initialTargetChannel = location.initial.dropTargets[0]?.data.channel;

        const startSrc = source.data.channel;
        const destinationSrc = destination.data.channel;

        if (
          !isChannel(currentTargetChannel) ||
          !isChannel(initialTargetChannel) ||
          !isChannel(startSrc) ||
          !isChannel(destinationSrc)
        ) {
          return;
        }

        if (currentTargetChannel.id === initialTargetChannel.id) {
          return;
        }

        const channels = previewChannelsRef.current;

        if (destinationSrc.isAdult) return;

        const startIndex = channels.findIndex((i) => i.id === startSrc.id);

        if (sourceIndexRef.current === null) {
          sourceIndexRef.current = startIndex;
        }

        const destinationIndex = channels.findIndex((i) => i.id === destinationSrc.id);

        const updated = reorder({ list: previewChannelsRef.current, startIndex, finishIndex: destinationIndex });

        previewChannelsRef.current = updated;
      },
      onDrop({ source, location }) {
        const destination = location.initial.dropTargets[0];

        if (!destination) {
          return;
        }

        if (!previewChannelsRef.current) {
          return;
        }

        const channels = previewChannelsRef.current;

        const destinationSrc = destination.data.channel;
        const startSrc = source.data.channel;

        if (!isChannel(startSrc) || !isChannel(destinationSrc)) {
          return;
        }

        const startIndex = sourceIndexRef.current ?? channels.findIndex((i) => i.id === startSrc.id);
        const destinationIndex = channels.findIndex((i) => i.id === destinationSrc.id);

        sourceIndexRef.current = null;

        if (startIndex === destinationIndex) {
          return;
        }

        const isMovingForward = startIndex < destinationIndex;

        if (isMovingForward) {
          const updatedChannels = shiftChannelNumbersForward({ startIndex, destinationIndex, channels });

          setLineupChange(updatedChannels);

          return;
        }

        const updatedChannels = shiftChannelNumbersBackward({ startIndex, destinationIndex, channels });

        setLineupChange(updatedChannels);
      },
    });
  }, [originalChannels, setLineupChange]);

  return <div ref={mainRef}>{children}</div>;
}
