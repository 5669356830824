import React, { createContext, useContext, useMemo } from 'react';

import { CollectionGridSettings } from '@kaltura-ott/tvpil-shared';

import { getImageSizeParams } from 'utils/getImageSizeParams';

interface Props {
  gridSettingsData: CollectionGridSettings;
  children: React.ReactNode;
}

interface CollectionGridSettingsContextType {
  gridSettingsData: CollectionGridSettings;
  imageHeight: number; // should be removed after fallback params becomes required
  imageWidth: number; // should be removed after fallback params becomes required
}

const CollectionGridSettingsContext = createContext<CollectionGridSettingsContextType>({
  gridSettingsData: {} as CollectionGridSettings,
  imageHeight: 0, // should be removed after fallback params becomes required
  imageWidth: 0, // should be removed after fallback params becomes required
});

const CollectionGridSettingsProvider = ({ gridSettingsData, children }: Props) => {
  const contextValue = useMemo(() => {
    const { imageHeight, imageWidth } = getImageSizeParams(gridSettingsData); // should be removed after fallback params becomes required

    return {
      gridSettingsData,
      imageHeight,
      imageWidth,
    };
  }, []);

  return (
    <CollectionGridSettingsContext.Provider value={contextValue}>{children}</CollectionGridSettingsContext.Provider>
  );
};

const useCollectionGridSettingsContext = () => useContext(CollectionGridSettingsContext);

export { CollectionGridSettingsProvider, useCollectionGridSettingsContext };
