import React from 'react';

import { RailSkeletonAsset } from 'components/widgets/Rail/components/RailSkeleton/RailSkeleton';
import { useSeriesCatchUpGridSettingsContext } from 'context/seriesCatchUpGridSettingsContext/seriesCatchUpGridSettingsContext';

import SeriesCatchUpGridCardLogic from '../SeriesCatchUpGridCardLogic/SeriesCatchUpGridCardLogic';

const SeriesCatchUpGridCardResolveRendering = ({ data }: { data: any }) => {
  const {
    gridSettingsData,
    imageHeight, // should be removed after fallback params becomes required
    imageWidth, // should be removed after fallback params becomes required
  } = useSeriesCatchUpGridSettingsContext();
  // const { imageHeight, imageWidth } = gridSettingsData;
  const columnWidth = Math.round(imageWidth);

  if (!data?.id) {
    return <RailSkeletonAsset height={imageHeight} width={columnWidth} />;
  }

  return <SeriesCatchUpGridCardLogic data={data} gridSettingsData={gridSettingsData} imageWidth={imageWidth} />;
};

export default SeriesCatchUpGridCardResolveRendering;
