import React from 'react';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { ADDITIONAL_HEIGHT, CARD_PADDING, INITIAL_OFFSET } from '../../constants';
import { RailCardItem } from '../RailCardItem/RailCardItem';

import { useRailCardsListCombiner } from './hooks/useRailCardsListCombiner/useRailCardsListCombiner';

import styles from './RailCardsList.module.scss';

type Props = {
  height: number;
  width: number;
};

function RailCardsList({ height, width }: Props) {
  const {
    isLogoRail,
    outerRef,
    railCardsListRef,
    items,
    listWidth,
    savedOffset,
    nextPageHandle,
    totalCount,
    imageWidth,
    imageHeight,
  } = useRailCardsListCombiner(width);

  return (
    <InfiniteLoader
      itemCount={totalCount}
      isItemLoaded={(index) => index < items.length}
      loadMoreItems={nextPageHandle}
    >
      {({ onItemsRendered }) => (
        <List
          ref={railCardsListRef}
          className={styles.itemsWrapper}
          height={height}
          initialScrollOffset={savedOffset}
          innerElementType='ul'
          itemCount={items.length}
          itemData={items}
          itemSize={() => imageWidth + CARD_PADDING}
          layout='horizontal'
          onItemsRendered={onItemsRendered}
          outerRef={outerRef}
          // TODO: check next slide assets
          overscanCount={10}
          width={listWidth}
        >
          {({ index, style, data }) => {
            const paddingTop = isLogoRail ? 0 : ADDITIONAL_HEIGHT - 10; // scale + shadow - design
            const asset = data[index];

            return (
              <li
                key={asset.id}
                style={{
                  ...style,
                  boxSizing: 'border-box',
                  left: Number(style.left) + INITIAL_OFFSET,
                  paddingRight: CARD_PADDING,
                  paddingTop,
                }}
              >
                <RailCardItem imageHeight={imageHeight} itemWidth={Number(style.width) - CARD_PADDING} asset={asset} />
              </li>
            );
          }}
        </List>
      )}
    </InfiniteLoader>
  );
}

export default React.memo(RailCardsList);
