import { useRailSettings, useSeriesCatchUpRailCollection } from '@kaltura-ott/tvpil-shared';

import { DEFAULT_HEIGHT, LANDSCAPE_RATIO, RAIL_PAGE_SIZE } from 'consts';

function useSeriesCatchUpRailCombiner(referenceId: string) {
  const settings = useRailSettings({
    referenceId,
    defaultLandscapeImageHeight: DEFAULT_HEIGHT,
    defaultPortraitImageHeight: DEFAULT_HEIGHT,
    defaultImageRatio: LANDSCAPE_RATIO,
  });
  const data = useSeriesCatchUpRailCollection(referenceId, RAIL_PAGE_SIZE);

  return { data, railSettings: settings };
}

export { useSeriesCatchUpRailCombiner };
