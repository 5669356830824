import React from 'react';

import { Linear } from '@kaltura-ott/tvpil-shared';

import { FavoriteIcon, PurchaseIcon } from 'components/presenters/icons';
import { useEpgStore } from 'hooks';

import { useChannelCombiner } from './hooks';

import styles from './Channel.module.scss';

interface Props {
  channel: Linear;
}

function Channel({ channel }: Props) {
  const {
    data: { title, id },
    isPurchasable,
    channelNumber,
  } = channel;
  const { epgStore } = useEpgStore();
  const { originImage } = useChannelCombiner(channel);

  return (
    <li className={styles.item}>
      {isPurchasable && <PurchaseIcon className={styles.purchase} />}
      {!isPurchasable && !!channelNumber && <span className={styles.number}>{channelNumber}</span>}
      {originImage?.url ? (
        <img alt={title} className={styles.logo} src={originImage.url} />
      ) : (
        <span className={styles.name}>{title}</span>
      )}
      <FavoriteIcon
        className={styles.favorite}
        isPurchasable={isPurchasable}
        linearId={id}
        presentOnRail={epgStore.presentFavoriteIndicator}
      />
    </li>
  );
}

export default React.memo(Channel);
