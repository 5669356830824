import { useEffect, useMemo, useState } from 'react';

import { MARGIN_BOTTOM, MARGIN_HORIZONTAL, MARGIN_RIGHT } from 'consts';
import { useSeriesCatchUpGridSettingsContext } from 'context/seriesCatchUpGridSettingsContext/seriesCatchUpGridSettingsContext';

function useSeriesCatchUpGridCardsSizeChecker() {
  const {
    // gridSettingsData: { imageHeight, imageWidth },
    imageHeight, // should be removed after fallback params becomes required
    imageWidth, // should be removed after fallback params becomes required
  } = useSeriesCatchUpGridSettingsContext();
  const [windowSize, setWindowSize] = useState({
    windowWidth: document.body.clientWidth,
    windowHeight: document.body.clientHeight,
  });
  const defaultHeight = imageHeight + MARGIN_BOTTOM;

  function resizeHandler(event: Event) {
    setWindowSize({
      windowWidth: (event.currentTarget as Window).innerWidth,
      windowHeight: (event.currentTarget as Window).innerHeight,
    });
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return useMemo(() => {
    const columnWidth = Math.round(imageWidth);
    const scrollBarWidth = window.innerWidth - document.body.clientWidth;
    // add scrollbar width to have constant containerWidth during lazy loading
    const containerWidth = document.body.clientWidth - MARGIN_HORIZONTAL + scrollBarWidth;
    const columnCount = Math.floor(containerWidth / (columnWidth + MARGIN_RIGHT)) || 1;

    return {
      windowWidth: windowSize.windowWidth,
      windowHeight: windowSize.windowHeight,
      containerWidth,
      columnCount,
      columnWidth,
      itemWidth: imageWidth,
      defaultHeight,
    };
  }, [windowSize.windowWidth, windowSize.windowHeight, defaultHeight]);
}

export { useSeriesCatchUpGridCardsSizeChecker };
