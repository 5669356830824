import React from 'react';

import RailProvider from '../components/RailProvider/RailProvider';
import RailView from '../components/RailView/RailView';
import RailWrapper from '../components/RailWrapper/RailWrapper';
import { RAIL_TYPE } from '../constants';

import { useCollectionRailCombiner } from './hooks/useCollectionRailCombiner/useCollectionRailCombiner';

interface Props {
  referenceId: string;
  index: number;
}

function CollectionRail({ referenceId, index }: Props) {
  const { data, railSettings } = useCollectionRailCombiner(referenceId);
  const railType = RAIL_TYPE.COLLECTION;

  return (
    <RailWrapper
      index={index}
      railType={railType}
      isLoading={data.isLoading}
      items={data.items}
      railSettings={railSettings!}
      hasError={!!data.error}
    >
      <RailProvider index={index} railType={railType} railSettings={railSettings!} railData={data!}>
        <RailView />
      </RailProvider>
    </RailWrapper>
  );
}

export default CollectionRail;
