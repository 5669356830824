import React, { createContext, useContext, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';

import { useRailProviderCombiner } from './hooks/useRailProviderCombiner/useRailProviderCombiner';
import { RailData, RailSettings } from './types';

export interface ContextType<T extends { id: number }> {
  data: RailData<T>;
  settings: RailSettings;
  type: string;
  index: number;
  checkResize: () => void;
  onHover: (isHover: boolean, value?: number) => void;
  onCardClick: (id: number) => void;
  setNextButtons: (value: any) => void;
  setRailCardsListRefScrollTo: (value: any) => void;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  totalWidth: number;
  componentHeight: number;
  headerRef: React.MutableRefObject<HTMLElement | null>;
  refElementInner: React.MutableRefObject<HTMLDivElement | null>;
  railCardsListRef: React.MutableRefObject<List | null>;
  nextButtons: React.MutableRefObject<any> | undefined;
  isLogoRail: boolean;
  offset: React.MutableRefObject<any> | undefined;
  savedOffset: number | undefined;
  imageHeight: number; // should be removed after fallback params becomes required
  imageWidth: number; // should be removed after fallback params becomes required
}

export interface Props<T extends { id: number }> {
  data: RailData<T>;
  settings: RailSettings;
  children: React.ReactNode;
  type: string;
  index: number;
  pageReferenceId?: string;
  referenceId?: string;
}

const defaultContextValue = <T extends { id: number }>(): ContextType<T> => ({
  data: {} as RailData<T>,
  settings: {} as RailSettings,
  type: '',
  index: 0,
  checkResize: () => {},
  onHover: () => {},
  onCardClick: () => {},
  setNextButtons: () => {},
  setRailCardsListRefScrollTo: () => {},
  wrapperRef: { current: null },
  totalWidth: 0,
  componentHeight: 0,
  headerRef: { current: null },
  refElementInner: { current: null },
  railCardsListRef: { current: null },
  nextButtons: undefined,
  isLogoRail: false,
  offset: undefined,
  savedOffset: 0,
  imageHeight: 0, // should be removed after fallback params becomes required
  imageWidth: 0, // should be removed after fallback params becomes required
});

const RailContext = createContext<ContextType<any>>(defaultContextValue());

const RailProviderV2 = <T extends { id: number }>({
  children,
  data,
  settings,
  type,
  index,
  pageReferenceId,
  referenceId,
}: Props<T>) => {
  const {
    checkResize,
    onHover,
    onCardClick,
    setNextButtons,
    setRailCardsListRefScrollTo,
    wrapperRef,
    totalWidth,
    componentHeight,
    headerRef,
    refElementInner,
    railCardsListRef,
    offset,
    nextButtons,
    isLogoRail,
    savedOffset,
    imageHeight, // should be removed after fallback params becomes required
    imageWidth, // should be removed after fallback params becomes required
  } = useRailProviderCombiner<T>(settings, data, type, pageReferenceId, referenceId);

  const contextValue = useMemo(
    () => ({
      data,
      settings,
      type,
      checkResize,
      onHover,
      onCardClick,
      setNextButtons,
      setRailCardsListRefScrollTo,
      wrapperRef,
      totalWidth,
      componentHeight,
      headerRef,
      refElementInner,
      railCardsListRef,
      offset,
      index,
      nextButtons,
      isLogoRail,
      savedOffset,
      imageHeight, // should be removed after fallback params becomes required
      imageWidth, // should be removed after fallback params becomes required
    }),
    [data.isLoading, settings.imageHeight],
  );

  return <RailContext.Provider value={contextValue}>{children}</RailContext.Provider>;
};

const useRailContext = <T extends { id: number }>(): ContextType<T> => useContext(RailContext);

export { RailProviderV2, useRailContext };

export default RailProviderV2;
