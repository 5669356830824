import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useCollectionGridSettings, useProviderLogo } from '@kaltura-ott/tvpil-shared';

import { COMPONENT_NAME } from 'components/pages/Grids/constants';
import { DEFAULT_HEIGHT, LANDSCAPE_RATIO } from 'consts';
import routes from 'routesPaths';
import { applyStyleHexToHSL, applyStyles } from 'utils';

import { useGridMatchPath } from '../../../common/hooks/useGridMatchPath/useGridMatchPath';
import { GridSettingsFetchDataTypes } from '../../../types';

function useSeriesCatchUpGridHooksCombiner() {
  const { type, railId } = useGridMatchPath(routes.seriesCatchUpGrid.path);
  const [, setForceUpdate] = useState(false); // For force updating of component
  const gridSettings = useCollectionGridSettings({
    gridReferenceId: type!,
    railReferenceId: railId!,
    defaultLandscapeImageHeight: DEFAULT_HEIGHT,
    defaultPortraitImageHeight: DEFAULT_HEIGHT,
    defaultImageRatio: LANDSCAPE_RATIO,
  });
  const providerLogo = useProviderLogo(gridSettings.settings?.providerName);
  const wrapperRef = useRef(null);
  const intl = useIntl();
  const notValidPageTitle = intl.formatMessage({
    id: `${COMPONENT_NAME}.notValidPage`,
    defaultMessage: 'Not a valid page',
  });

  useEffect(() => {
    async function initGridPage() {
      const node = wrapperRef?.current;

      if (node && gridSettings.settings?.branding!) {
        applyStyles(node, gridSettings.settings.branding, false, true);
        applyStyleHexToHSL(node, gridSettings.settings.branding.backgroundColor);
      }
    }

    if (gridSettings && !gridSettings.error) {
      initGridPage();
    }
  }, [type, railId, gridSettings.isLoading]);

  return ({
    gridSettings: gridSettings.settings,
    isLoading: gridSettings.isLoading,
    wrapperRef,
    providerLogo,
    notValidPageTitle,
    setForceUpdate,
    isNoContent: !gridSettings.settings && !gridSettings.isLoading,
  } as unknown) as GridSettingsFetchDataTypes;
}

export { useSeriesCatchUpGridHooksCombiner };
