import React from 'react';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import RailSkeleton from 'components/widgets/RailV2/components/RailSkeleton/RailSkeleton';
import { RAIL_PAGE_SIZE } from 'consts';

import RailProviderV2 from '../components/RailProviderV2/RailProviderV2';

import RecommendationRailContainer from './components/RecommendationRailContainer/RecommendationRailContainer';
import { useRecommendationRail } from './hooks/useRecommendationRail/useRecommendationRail';

interface Props {
  referenceId: string;
  index: number;
}

function RecommendationRail({ referenceId, index }: Props) {
  const { railData, railType, railSettings, isRailHidden } = useRecommendationRail(referenceId);

  if (!railSettings || isRailHidden) {
    return null;
  }

  if (!railData.items.length) {
    return <RailSkeleton railType={railType} settings={railSettings} items={RAIL_PAGE_SIZE} />;
  }

  return (
    <RailProviderV2<CollectionAsset>
      index={index}
      type={railType}
      data={railData}
      settings={railSettings}
      referenceId={referenceId}
    >
      <RecommendationRailContainer />
      <div />
    </RailProviderV2>
  );
}

export default RecommendationRail;
