import React from 'react';

import { RailSkeletonAsset } from 'components/widgets/Rail/components/RailSkeleton/RailSkeleton';
import { useCollectionGridSettingsContext } from 'context/collectionGridSettingsContext/collectionGridSettingsContext';

import GridCollectionCardLogic from '../GridCollectionCardLogic/GridCollectionCardLogic';

const GridCollectionCardResolveRendering = ({ data }: { data: any }) => {
  const {
    gridSettingsData,
    imageHeight, // should be removed after fallback params becomes required
    imageWidth, // should be removed after fallback params becomes required
  } = useCollectionGridSettingsContext();
  // const { imageHeight } = gridSettingsData;
  const columnWidth = Math.round(imageWidth); // should be removed after fallback params becomes required

  if (!data?.id) {
    return <RailSkeletonAsset height={imageHeight} width={columnWidth} />;
  }

  return <GridCollectionCardLogic data={data} gridSettingsData={gridSettingsData} imageWidth={imageWidth} />;
};

export default GridCollectionCardResolveRendering;
