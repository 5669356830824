import React from 'react';

import { CollectionGridSettings } from '@kaltura-ott/tvpil-shared';

import Card from 'components/widgets/Card/Card';
import { CardProvider } from 'context/сardContext/сardContext';

import { useResolveCollectionGridCardProps } from '../GridCollectionCardResolveRendering/hooks/useResolveCollectionGridCardProps/useResolveCollectionGridCardProps';

interface Props {
  data: any;
  gridSettingsData: CollectionGridSettings;
  imageWidth: number; // should be removed after fallback params becomes required
}

function GridCollectionCardLogic({ data, gridSettingsData, imageWidth }: Props) {
  const resolvedCollectionGridCardProps = useResolveCollectionGridCardProps({ data, gridSettingsData });

  return (
    <CardProvider resolvedCardProps={resolvedCollectionGridCardProps} itemWidth={imageWidth} data={data}>
      <Card />
    </CardProvider>
  );
}

export default React.memo(GridCollectionCardLogic);
