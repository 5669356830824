import { useIntl } from 'react-intl';
import { AssetWithSeriesName } from 'types';

import { useCardRecordInfo } from 'components/widgets/Card/hooks';
import { useCardContext } from 'context/сardContext/сardContext';
import { useParentalPinStore } from 'hooks';

function useCardTitleCombiner() {
  const intl = useIntl();
  const {
    data,
    resolvedCardProps: {
      adult,
      isExternalProviderAsset,
      mdIcon,
      isMosaicHeroRailProgram,
      seasonEpisodeTitle,
      isProgramAsset,
      isTypeOfSeries,
      isCollectionEpisode,
      isAsPlayAsset,
      hasSeriesId,
    },
  } = useCardContext();
  const parentalPinStore = useParentalPinStore();
  const isBlockByAdult = !parentalPinStore.isAdultPinEntered;
  const isContentRestricted = !!(adult && isBlockByAdult && !isExternalProviderAsset);
  const { isRecord, isSeriesType } = useCardRecordInfo({
    data,
    isProgramAsset,
    isTypeOfSeries,
    isCollectionEpisode,
  });
  const isWrapperWithIcon = isRecord || isMosaicHeroRailProgram;
  const isWithSeriesRecord = isRecord && isSeriesType;
  let resolvedTitle = data?.title;

  if (hasSeriesId && (data as AssetWithSeriesName)?.seriesName!) {
    resolvedTitle = (data as AssetWithSeriesName)?.seriesName;
  }

  if (isContentRestricted) {
    // TODO: we need to discuss a new approach to naming strings
    resolvedTitle = intl.formatMessage({
      id: 'Rail.RailCard.restrictedContent',
      defaultMessage: 'Restricted content',
    });
  }

  return {
    data,
    mdIcon,
    resolvedTitle,
    isRecord,
    isSeriesType,
    isMosaicHeroRailProgram,
    seasonEpisodeTitle,
    isWrapperWithIcon,
    isWithSeriesRecord,
    isNoTransparent: !isAsPlayAsset,
  };
}

export { useCardTitleCombiner };
